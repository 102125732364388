import React, { useEffect, useState } from 'react';
import './profile.scss';
import { DashboardPage } from '../dashboard/dashboard'
import { GetRecentLogs } from './GetRecentLogs'
import { storeService } from '../../services/api/store-service';
import { localStorageService } from '../../services/utilities/localStorageService';
import { Button } from 'devextreme-react/button';
import LoadPanel from 'devextreme-react/load-panel';
import { toast } from '../../helper/notifyBox';
import { deviceService } from '../../services/api/device-service';
import SelectBox from 'devextreme-react/select-box';


function ProfilePage() {
  const [dataSource, setDataSource] = useState({});
  const [loader, setLoader] = useState(false);
  const [deviceDataSource, setDeviceDataSource] = useState();
  const [selectedDevice, setSelectedDevice] = useState();
  const deviceLabel = { 'aria-label': "Select a devices..." };


  useEffect(() => {
    setStoreData();
    setDeviceData();
  }, []);

  const setStoreData = async () => {
    setLoader(true);
    var response = await storeService.getStore(localStorageService.getStoreId())
    if (response) {
      setDataSource(response);
    }
    setLoader(false);
  }

  const setDeviceData = async () => {
    var responseDevices = await deviceService.getDeviceLookUp(localStorageService.getStoreId());    
    setDeviceDataSource(responseDevices);
    setSelectedDevice(responseDevices[0]);
  }

  async function saveStore(e) {
    setLoader(true);

    var response = await storeService.updateStore(dataSource)
    if (response) {
      toast("butikkinformasjonen er oppdatert", "success");
      setLoader(false);
      return;
    }
    toast("ikke klarer å lagre", "error");
    setLoader(false);

  }

  const handleChange = (e) => {
    dataSource[e.target.id] = e.target.value;
    setDataSource({ ...dataSource, [e.target.id]: e.target.value });
  };

  const onDeviceChanged = (async (arg) => {
    
  });

  const triggerMqttDevice = async (data, deviceStatus) => {
    setLoader(true);
    var updateInfo = {
      DeviceStatus:deviceStatus,
      DeviceUUID:selectedDevice.text
  }
    var response = await deviceService.triggerMqttDevice(updateInfo)
    if (response) {
        toast("butikkinformasjonen er oppdatert", "success");
        setLoader(false);
        return;
    }
    toast("ikke klarer å lagre", "error");
    setLoader(false);
};


  return (
    <React.Fragment>
      <LoadPanel
        shadingColor="rgba(0,0,0,0.4)"
        visible={loader}
        showIndicator={true}
      />
      <h2 className={'content-block'} style={{ 'margin-bottom': '5px', 'margin-top': '10px' }} >Profil</h2>

      <div className='profile-card-container d-flex' style={{ 'padding-top': '2px' }}>
        <div style={{ width: '70%' }}>
          <div className='profile-card' style={{ 'margin-bottom': '10px' }}>
            <div className='profile-card-panel'>

              <div className='title-text profile-card-panel-header'>Dashboard</div>
              <DashboardPage></DashboardPage>
            </div>
          </div>
          <div className='profile-card'>
            <div className='profile-card-panel'>
              <div className='title-text profile-card-panel-header'>Loggoppføringer
              </div>
              <GetRecentLogs></GetRecentLogs>
            </div>
          </div>
        </div>

        <div style={{ flex: 1 }}>
          <div className='profile-card' style={{ flex: '1', 'padding-bottom': '10px' }} >
            <div className='profile-card-panel' style={{ 'height': '100%' }}>
              <div className='title-text profile-card-panel-header' style={{ 'padding-bottom': '30px', 'border-bottom': '0px' }}>
                <div className='title-text profile-card-panel-header'>Enhetsstatus
                </div>
                <div className="dx-fieldset">
                  <div className="dx-field" style={{ float: 'left', 'transform': 'translate(0, -50%)', 'width': '60%', 'display': 'grid' }}>
                    <div className="dx-field-value" style={{ 'width': '100%' }} >
                      <SelectBox
                        items={deviceDataSource}
                        value={selectedDevice}
                        selectedItem={selectedDevice}
                        displayExpr='text'
                        inputAttr={deviceLabel}
                        onValueChanged={onDeviceChanged} />
                    </div>
                  </div>
                  <div style={{ float: 'right', 'transform': 'translate(0, -50%)', 'position': 'relative' }}>
                    <button className='margin1px'><img src="/on.png" style={{ 'width': '30px' }} alt="my image" onClick={(e) => triggerMqttDevice(e,true)} title="på"/></button>
                    <button className='margin1px'><img src="/off.png" style={{ 'width': '30px' }} alt="my image" onClick={(e) => triggerMqttDevice(e,false)}title="av" /></button>
                    <button className='margin1px'><img src="/trigger.png" style={{ 'width': '30px' }} alt="my image" onClick={(e) => triggerMqttDevice(e,'')} title="avtrekker" /></button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='profile-card' style={{ flex: '1' }} >
            <div className='profile-card-panel' style={{ 'height': '100%' }}>
              <div className='title-text profile-card-panel-header' style={{ 'padding-top': '30px', 'padding-bottom': '30px' }}>
                <div style={{ float: 'left', 'transform': 'translate(0, -50%)', 'position': 'relative' }}>
                  Rediger butikk
                </div>
                <div className='rightAlignDiv'>
                  <Button
                    text="Lagre"
                    type="normal"
                    stylingMode="outlined"
                    style={{ position: 'inherit', height: '40px', borderRadius: '0px', marginLeft: '5px' }}
                    onClick={saveStore}
                  /></div>
              </div>
              <div className="dx-fieldset">
                <div className="dx-field">
                  <div className="dx-field-label">Butikknavn</div>
                  <div className="dx-field-value dx-show-invalid-badge dx-textbox dx-texteditor dx-editor-filled dx-texteditor-empty dx-widget">
                    <input title='name' role='textbox' type="text" class='dx-texteditor-input' id='name' value={dataSource?.name} onChange={handleChange} />
                  </div>
                </div>
                <div className="dx-field">
                  <div className="dx-field-label">Addresse</div>
                  <div className="dx-field-value dx-show-invalid-badge dx-textbox dx-texteditor dx-editor-filled dx-texteditor-empty dx-widget">
                    <input value={dataSource?.adress} title='adress' role='textbox' type="text" class='dx-texteditor-input' id='adress' onChange={handleChange} />
                  </div>
                </div>
                <div className="dx-field">
                  <div className="dx-field-label">Postnr.</div>
                  <div className="dx-field-value dx-show-invalid-badge dx-textbox dx-texteditor dx-editor-filled dx-texteditor-empty dx-widget">
                    <input value={dataSource?.postCode} title='postCode' role='textbox' type="text" class='dx-texteditor-input' id='postCode' onChange={handleChange} />
                  </div>
                </div>
                <div className="dx-field">
                  <div className="dx-field-label">Poststed</div>
                  <div className="dx-field-value dx-show-invalid-badge dx-textbox dx-texteditor dx-editor-filled dx-texteditor-empty dx-widget">
                    <input value={dataSource?.city} title='city' role='textbox' type="text" class='dx-texteditor-input' id='city' onChange={handleChange} />
                  </div>
                </div>
                <div className="dx-field">
                  <div className="dx-field-label">Support Epost</div>
                  <div className="dx-field-value dx-show-invalid-badge dx-textbox dx-texteditor dx-editor-filled dx-texteditor-empty dx-widget">
                    <input value={dataSource?.contactEmail} title='contactEmail' role='textbox' type="text" class='dx-texteditor-input' id='contactEmail' onChange={handleChange} />
                  </div>
                </div>
                <div className="dx-field">
                  <div className="dx-field-label">Support Tlf.</div>
                  <div className="dx-field-value dx-show-invalid-badge dx-textbox dx-texteditor dx-editor-filled dx-texteditor-empty dx-widget">
                    <input value={dataSource?.contactPhone} title='contactPhone' role='textbox' type="text" class='dx-texteditor-input' id='contactPhone' onChange={handleChange} />
                  </div>
                </div>
                <div className="dx-field">
                  <div className="dx-field-label">Kontakt instruksjoner</div>
                  <div className="dx-field-value dx-show-invalid-badge dx-textbox dx-texteditor dx-editor-filled dx-texteditor-empty dx-widget">
                    <input value={dataSource?.contactInstructions} title='contactInstructions' role='textbox' type="text" class='dx-texteditor-input' id='contactInstructions' onChange={handleChange} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </React.Fragment>
  );

}

export { ProfilePage };