import defaultUser from '../utils/default-user';
import { authenticationService } from '../services/api/authentication-service';
import { localStorageService } from '../services/utilities/localStorageService';

export async function signIn(email, password, chainName) {
  try {
    var userData = null;
    var errorMessage = "";
    await authenticationService.login(email, password, chainName)
      .then(
        authResponse => {
          userData = authResponse;
        }
      ).catch(error => {
        errorMessage = error;

        return Promise.reject(error);
    });
    return {
      isOk: true,
      data: userData
    };
  }
  catch {
    return {
      isOk: false,
      message: errorMessage
    };
  }
}

export async function getUser() {
  try {
    // Send request

    return {
      isOk: true,
      data: defaultUser
    };
  }
  catch {
    return {
      isOk: false
    };
  }
} 
