import { ApiUrlConstants } from '../api/apiUrl.constants';
import { localStorageService } from "../utilities/localStorageService";
import notify from 'devextreme/ui/notify';

export const authenticationService = {
    login
};

function login(username, password, chainName) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, password, chainName })
    };
    return fetch(ApiUrlConstants.AccessControl.LOGIN, requestOptions)
        .then(handleResponse)
        .then(authResponse => {
            return authResponse;
        });
} 

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                localStorageService.clearStorage();
                //window.location.reload(true);
            }
            const error = (data && data.message) || response.statusText;
            toast(error, 'warning');
            return Promise.reject(error);
        }

        return data;
    });
}


function toast(message, infoType) {
    notify({
        message: message,
        position: {
            my: 'center top',
            at: 'center top'
        }
    }, infoType, 3000);
}