import 'devextreme/data/odata/store';
import CustomStore from 'devextreme/data/custom_store';
import { blacklistService } from '../../services/api/blacklist-service';
import { toast } from '../../helper/notifyBox';

export function blacklistDataSource(storeId, deviceId) {
    return {
        store: new CustomStore({
            key: 'id',
            load: async (loadOptions) => {
                let params = '?';
                [
                    'skip',
                    'take',
                    'requireTotalCount',
                    'requireGroupCount',
                    'sort',
                    'filter',
                    'totalSummary',
                    'group',
                    'groupSummary'
                ].forEach(function (i) {
                    if (i in loadOptions && loadOptions[i]) {
                        params += `${i}=${JSON.stringify(loadOptions[i])}&`;
                    }
                });
                let taskData = await blacklistService.getAllBlacklists(storeId, deviceId);
                return taskData;
            },
            insert: async (values) => {
                if (values != null) {                    
                    values.storeId = storeId;
                    values.byAdmin = true;
                    let response = await blacklistService.addBlacklist(values);
                    if (response === true)
                        toast("Blacklist is created ", "success");
                }
            },

            update: async (key, values) => {
                values.id = key;
                if (key > 0) {
                    var blacklist = await blacklistService.getBlacklist(key);
                    if (blacklist != null) {
                        var updateInfo = {
                            ...blacklist,
                            ...values
                        }
                        let response = await blacklistService.updateBlacklist(updateInfo);
                        if (response === true)
                            toast("Blacklist is updated ", "success");
                    }
                }
            },
            remove: async (key) => {
                if (key > 0) {
                    var response = await blacklistService.deleteBlacklist(key);
                    if (response === true)
                        toast("Blacklist is deleted ", "success");
                }
            },
        })
    }
}

