import React, { useState, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import Form, {
  Item,
  Label,
  ButtonItem,
  ButtonOptions,
  RequiredRule,
  EmailRule
} from 'devextreme-react/form';
import LoadIndicator from 'devextreme-react/load-indicator';
import notify from 'devextreme/ui/notify';
import './LoginForm.scss';
import { signIn as sendSignInRequest } from '../../api/auth';
import { localStorageService } from '../../services/utilities/localStorageService';
import { toast } from '../../helper/notifyBox';
import { NULL } from 'sass';

export default function LoginForm() {
  const navigate = useNavigate();
  const [isChecked, setIsChecked] = useState(localStorageService.getIsChecked());
  const [loading, setLoading] = useState(false);
  const formData = useRef({ email: ((localStorageService.getChainName() === null || localStorageService.getChainName() === "" ? '' : localStorageService.getChainName() + ':') + (localStorageService.getEmail() ?? '')), password: '' });

  const signIn = useCallback(async (email, password) => {
    var chainName = "";
    var chainAndEmail = email.split(':');
    if (chainAndEmail.length > 1) {
      chainName = chainAndEmail[0];
      email = chainAndEmail[1];
    }
    else {
      email = chainAndEmail[0];
      chainName = "";
    }

    const result = await sendSignInRequest(email, password, chainName);
    if (result.isOk) {
      localStorageService.setChainName(chainName);
      localStorageService.setEmail(result.data.email);
      localStorageService.setAuthToken(result.data.token);
      localStorageService.setRoleName(result.data.roleName);
      localStorageService.setStoreId(result.data.storeId);
      localStorageService.setStoreName(result.data.storeName);
    }
    return result;
  }, []);

  const GetSessionValue = () => {
    var email = "";
    if(localStorageService.getChainName() === null)
    {
      if(localStorageService.getEmail() !== null)
      {
        email = localStorageService.getEmail()
      }
      else{
        email = "";
      }
    }
    else{
      email = "";
    }

    return email;
  }
  const onSubmit = useCallback(async (e) => {
    e.preventDefault();
    const { email, password } = formData.current;
    setLoading(true);

    const result = await signIn(email, password);
    if (!result.isOk) {
      setLoading(false);
      toast(result.message, "error");
    }
    else {
      window.location.reload();
    }
  }, [signIn]);

  const onChange = (e) => {
    setIsChecked(e.target.checked);
    localStorageService.setIsChecked(e.target.checked)
  };

  return (
    <form className={'login-form'} onSubmit={onSubmit}>
      <Form formData={formData.current} disabled={loading}>
        <Item
          dataField={'email'}
          editorType={'dxTextBox'}
        editorOptions={emailEditorOptions}
        >
          <Label visible={false} />
        </Item>
        <Item
          dataField={'password'}
          editorType={'dxTextBox'}
          editorOptions={passwordEditorOptions}
        >
          <RequiredRule message="Password is required" />
          <Label visible={false} />
        </Item>
        <Item>
          <label>
            <input type="checkbox"
              checked={isChecked}
              onChange={e => onChange(e)} />
            <span>Husk meg</span>
          </label>
        </Item>
        <ButtonItem>
          <ButtonOptions
            width={'100%'}
            type={'default'}
            useSubmitBehavior={true}
          >
            <span className="dx-button-text">
              {
                loading
                  ? <LoadIndicator width={'24px'} height={'24px'} visible={true} />
                  : 'Sign In'
              }
            </span>
          </ButtonOptions>
        </ButtonItem>
      </Form>
    </form>
  );
}

const emailEditorOptions = { stylingMode: 'filled', placeholder: 'Email', mode: 'text' };
const passwordEditorOptions = { stylingMode: 'filled', placeholder: 'Password', mode: 'password' };
