import axios from 'axios'; 

async function getAll(url) {
    return await axios.get(url);
}

async function getSingle(url, payload) {
    return await axios.get(url, payload).catch(catchError);
}

async function post(url, model) {
    return await axios.post(url, model).catch(catchError);
}

async function put(url, model) {
    return await axios.put(url, model).catch(catchError);
}

async function remove(url) {
    return await axios.delete(url).catch(catchError);
}
 

export const axiosProvider = {
    getAll,
    getSingle,
    post,
    put,
    remove,
};

//request interceptor
axios.interceptors.request.use(
    function (config) { 
        return config
    },
    function (error) {
        catchError(error);
    }
)

//response interceptor
axios.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        return Promise.reject(error);
    }
)

function catchError(error) {
    if (error && error.response) {
        const code = error.response.status
        const response = error.response.data
        const originalRequest = error.config;

        if (code === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            //logout
        }

        if (code === 500) {
            return 'Noe gikk galt'
        }

        if (code === 401) {
            const errorMessage = response.data;
            return errorMessage;
        }

        const errorMessage = JSON.parse(response.data)[0].errorMessage;
        return errorMessage;
    }
    else {
        return error;
    }
}