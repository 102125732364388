export const navigation = [
  {
    text: 'Hjem',
    path: '/home',
    icon: 'home'
  },
  {
    text: 'Meny',
    icon: 'folder',
    items: [
      {
        text: 'Profil',
        path: '/profile'
      },
      {
        text: 'Butikker',
        path: '/stores'
      },
      {
        text: 'Enheter',
        path: '/devices'
      },
      {
        text: 'BlackList',
        path: '/blacklist'
      },
      {
        text: 'Logs',
        path: '/interactlogs'
      },
      {
        text: 'Scheduler',
        path: '/scheduler'
      }, 
      {
        text: 'Vipps Merchant',
        path: '/vippsMerchant'
      }
    ]
  }
  ];
