import React from 'react';
import { DashboardControl, FetchRemoteService } from 'devexpress-dashboard-react';
import { localStorageService } from '../../services/utilities/localStorageService';
import { ApiUrlConstants } from '../../services/api/apiUrl.constants';

const headerAuth = { "Authorization": "bearer " + localStorageService.getAuthToken() };
function DashboardPage() {
    return (
        <div>
            <DashboardControl className='scrollviewdash' style={{ width: '100%' }} workingMode="Viewer"
                endpoint={ApiUrlConstants.AccessControl.DASHBOARD}>
                <FetchRemoteService
                    headers={headerAuth} />
            </DashboardControl>
        </div>
    );
}

export { DashboardPage };