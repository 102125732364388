const prod = {
    apiGateway: {
        API_URL: "https://lasopp-api.touchsoft.no/api",
        DASHBOARD_URL: "https://lasopp-api.touchsoft.no",
    }
};

const stage = {
    apiGateway: {
        API_URL: "https://accesscontrolapi.akeodev.com/api"      ,
        DASHBOARD_URL: "https://accesscontrolapi.akeodev.com",  
    }
};

const dev = {
    apiGateway: {
        API_URL: "https://localhost:44381/api",
        DASHBOARD_URL: "https://localhost:44381/",  
    }
};

export function getAppSettings() {
    if (process.env.REACT_APP_ENV !== undefined && process.env.REACT_APP_ENV.toString().trim() === "production") {
        return prod;
    }
    else if (process.env.REACT_APP_ENV !== undefined && process.env.REACT_APP_ENV.toString().trim() === "staging") {
        return stage;
    }
    else {
        return dev;
    }
}
 