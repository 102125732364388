import React, { useEffect, useState } from 'react';
import DataGrid, {
  Column,
  FilterRow,
  Lookup,
  RequiredRule,
  Editing,
  Scrolling
} from 'devextreme-react/data-grid';
import LoadPanel from 'devextreme-react/load-panel';
import { vippsMerchantDataSource } from '../vippsMerchant/vippsMerchantDataSource';
import { storeService } from '../../services/api/store-service';

function VippsMerchantPage() {
  const [dataSource, setDataSource] = useState({});
  const [loader, setLoader] = useState(false);
  const requiredInput = 'This field is required';
  const [storeDataSource, setStoreDataSource] = useState();

  useEffect(() => {
    setGridData();
  }, []);

  const setGridData = async () => {
    setLoader(true);

    var responseStores = await storeService.getStoresLookUp();
    setStoreDataSource(responseStores);

    await setDataSource(vippsMerchantDataSource());
    setLoader(false);
  }


  return (
    <>
      <h2 className={'content-block'}>Vipps Merchant</h2>
      <LoadPanel
        shadingColor="rgba(0,0,0,0.4)"
        visible={loader}
        showIndicator={true}
      />
      <DataGrid
        height={600}
        className={'dx-card wide-card'}
        dataSource={dataSource}
        showBorders={false}
        columnAutoWidth={true}
        columnHidingEnabled={true}
        selection={{ mode: 'single' }}
        remoteOperations={true}
        allowColumnResizing={true}
      >
        <FilterRow visible={true} />
        <Scrolling mode="virtual" rowRenderingMode="virtual" />

        <Editing
          mode="row"
          allowAdding={true}
          allowUpdating={true}
          allowDeleting={true}
        />
        <Column
          dataField={'storeId'}
          caption={'Store Navn'}
          width={550}
        >
          <Lookup
            dataSource={storeDataSource}
            valueExpr={'id'}
            displayExpr={'text'}
          />
          <RequiredRule
            message={'Please select a store'}
          />
        </Column>
        <Column
          dataField={'organizationNo'}
          caption={'Organization No'}
          width={480}
        >
          <RequiredRule
            message={requiredInput}
          />
        </Column>
        <Column
          dataField={'merchantSerialNumber'}
          caption={'Merchant Serial Number'}
          width={480}
        >
          <RequiredRule
            message={requiredInput}
          />
        </Column>


      </DataGrid>
    </>
  )
}

export { VippsMerchantPage };