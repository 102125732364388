import 'devextreme/data/odata/store';
import CustomStore from 'devextreme/data/custom_store';
import { deviceService } from '../../services/api/device-service';
import { toast } from '../../helper/notifyBox';

export function deviceDataSource() {
    return {
        store: new CustomStore({
            key: 'id',
            load: async (loadOptions) => {
                let params = '?';
                [
                    'skip',
                    'take',
                    'requireTotalCount',
                    'requireGroupCount',
                    'sort',
                    'filter',
                    'totalSummary',
                    'group',
                    'groupSummary'
                ].forEach(function (i) {
                    if (i in loadOptions && loadOptions[i]) {
                        params += `${i}=${JSON.stringify(loadOptions[i])}&`;
                    }
                });
                params = params.slice(0, -1);
                let taskData = await deviceService.getAllDevices(params);
                return {
                    data: taskData.data,
                    totalCount: taskData.totalCount,
                    summary: taskData.summary,
                    groupCount: taskData.groupCount
                };
            },
            insert: async (values) => {
                if (values != null) {
                    let response = await deviceService.addDevice(values);
                    if (response === true)
                        toast("Device is created ", "success");
                }
            },

            update: async (key, values) => {
                values.id = key;
                if (key > 0) {
                    var device = await deviceService.getDevice(key);
                    if (device != null) {
                        var updateInfo = {
                            ...device,
                            ...values
                        }
                        let response = await deviceService.updateDevice(updateInfo);
                        if (response === true)
                            toast("Device is updated ", "success");
                    }
                }
            },
            remove: async (key) => {
                if (key > 0) {
                    var response = await deviceService.deleteDevice(key);
                    if (response === true)
                        toast("Device is deleted ", "success");
                }
            },
        })
    }
}

