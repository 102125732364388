import React, { useEffect, useState, useRef, useCallback } from 'react';
import LoadPanel from 'devextreme-react/load-panel';
import { schedulerDataSource, createSchduler, updateSchduler, deleteSchduler, formatOptions } from './schedulerDataSource';
import { DropDownBox } from 'devextreme-react/drop-down-box';
import { List } from "devextreme-react/list";
import { deviceService } from '../../services/api/device-service';
import { storeService } from '../../services/api/store-service';
import Scheduler from 'devextreme-react/scheduler';
import { toast } from '../../helper/notifyBox';
import { localStorageService } from "../../services/utilities/localStorageService";
import { roleEnum } from '../../helper/enumTypes';
import SchedulerViewTemplate from './template_scheduler';
import { Popup, Position, ToolbarItem } from 'devextreme-react/popup';

function SchedulerPage() {
    const [dataSource, setDataSource] = useState({});
    const [loader, setLoader] = useState(false);
    const [storeDataSource, setStoreDataSource] = useState();
    const [selectedStore, setSelectedStore] = useState();
    const dropDownBoxRef = useRef(null);
    const storeDDBRef = useRef(null);
    const [deviceDataSource, setDeviceDataSource] = useState();
    const [selectedDevice, setSelectedDevice] = useState();
    const [popupVisible, setPopupVisible] = useState(false);

    useEffect(() => {
        initLookUps();
    }, []);

    const initLookUps = async () => {
        setLoader(true);
        var responseStores = await storeService.getStoresLookUp();
        setStoreDataSource(responseStores);
        setLoader(false);
    }

    const setGridData = async (deviceId) => {
        setLoader(true);
        var data = await schedulerDataSource(deviceId);
        if (data.length === 0) {
            setPopupVisible(true);
        }

        setDataSource(data);
        setLoader(false);
    }

    const currentDate = new Date();
    const views = ['day', 'week', 'month'];

    const onStoreChanged = useCallback(async (arg) => {
        var store = arg.addedItems[0];
        if (store) {
            setSelectedStore(store.text);
            var responseDevices = await deviceService.getDeviceLookUp(store.id);
            setDeviceDataSource(responseDevices);
            if (responseDevices.length === 0) {
                resetData();
            }
        }
        storeDDBRef.current.instance.close();
    }, []);

    const onDeviceChanged = useCallback((arg) => {
        var device = arg.addedItems[0];
        if (device) {
            setSelectedDevice(device.text);
            setGridData(device.id);
        } else {
            resetData();
        }
        dropDownBoxRef.current.instance.close();
    }, []);

    const resetData = () => {
        setSelectedDevice('');
        setDataSource([]);
    }

    const onAppointmentAdding = (e) => {
        var appointment = e.appointmentData;
        if (hasPreviousDate(appointment)) {
            e.cancel = true;
        }
    };

    const onAppointmentUpdating = (e) => {
        var appointment = e.newData;
        if (hasPreviousDate(appointment)) {
            e.cancel = true;
        }
    };


    const onAppointmentAdded = async (e) => {
        if (deviceDataSource != null) {
            var device = deviceDataSource.find(x => x.text === selectedDevice);

            if (!device || device?.id <= 0) {
                toast("Choose device ", "warning");
                return;
            }

            var appointment = e.appointmentData;

            var formattedStartDate = appointment.start.toLocaleString("en", formatOptions);
            var formattedEndDate = appointment.end.toLocaleString("en", formatOptions);

            var schedulerModel = {
                start: formattedStartDate,
                end: formattedEndDate,
                allDay: appointment.allDay,
                subject: appointment.subject,
                description: appointment.description,
                recurrenceRule: appointment.recurrenceRule,
                deviceId: device.id,
                recurrenceException: appointment.recurrenceException
            }

            if (validateByDay(schedulerModel, device)) {
                return;
            }

            setLoader(true);
            let result = await createSchduler(schedulerModel);
            if (result) {
                toast("Scheduler is created.", "success");
                setGridData(device.id);
            }
            setLoader(false);
        }
    }

    const onAppointmentUpdated = async (e) => {
        // Handler of the "appointmentUpdated" event
        if (deviceDataSource != null) {

            var device = deviceDataSource.find(x => x.text === selectedDevice);

            if (!device || device?.id <= 0) {
                toast("Choose device ", "warning");
                return;
            }

            var appointment = e.appointmentData;

            var formattedStartDate = appointment.start.toLocaleString("en", formatOptions);
            var formattedEndDate = appointment.end.toLocaleString("en", formatOptions);

            var schedulerModel = {
                id: appointment.id,
                start: formattedStartDate,
                end: formattedEndDate,
                allDay: appointment.allDay,
                subject: appointment.subject,
                description: appointment.description,
                recurrenceRule: appointment.recurrenceRule,
                deviceId: device.id,
                recurrenceException: appointment.recurrenceException
            }

            if (validateByDay(schedulerModel, device)) {
                return;
            }

            setLoader(true);
            let result = await updateSchduler(schedulerModel);

            if (result) {
                toast("Scheduler is updated.", "success");
                setGridData(device.id);
            }
            setLoader(false);
        }
    }

    const onAppointmentDeleted = async (e) => {
        // Handler of the "appointmentDeleted" event
        if (deviceDataSource != null) {
            var appointment = e.appointmentData;
            var device = deviceDataSource.find(x => x.text === selectedDevice);

            if (!device || device?.id <= 0) {
                toast("Choose device ", "warning");
                return;
            }
            setLoader(true);
            let result = await deleteSchduler(appointment.id);

            if (result) {
                toast("Scheduler is deleted.", "success");
                setGridData(device.id);
            }
            setLoader(false);
        }
    }

    const validateByDay = (schedulerModel, device) => {
        let isValid = false;

        var byDayIndex = schedulerModel?.recurrenceRule?.search('BYDAY');
        if (byDayIndex > -1) {
            let multipleDays = schedulerModel?.recurrenceRule?.substr(byDayIndex).search(',')
            if (multipleDays > -1) {
                toast("Please choose single day.", "warning");
                setGridData(device.id);
                isValid = true;
            }
        }
        return isValid;
    }

    const hasPreviousDate = (appointment) => {
        let isPrevious = false;
        const startDate = new Date(appointment.start);
        if (startDate < new Date()) {
            toast("Cannot create or move scheduler to previous date.", "warning");
            isPrevious = true;
        }

        return isPrevious;
    }


    const getDefaultStoreId = async () => {
        var roleName = localStorageService.getRoleName();
        if (roleName.toLowerCase() === roleEnum.admin.toString()) {
            return null;
        }

        var storeID = localStorageService.getStoreId();
        if (storeDataSource != null) {
            var store = storeDataSource.find(x => x.id === parseInt(storeID));
            if (store != null && selectedStore == null) {
                var arg = {
                    addedItems: [
                        store
                    ]
                }
                onStoreChanged(arg);
                return store;
            }
        }
        return {};
    }

    const onTimeCellRender = (e) => {
        if (e.text !== "") {
            return new Date(e.date).toLocaleString("en-GB", { hour: "2-digit" });
        }
    }

    const onAppointmentFormOpening = (e) => {
        let form = e.form,
            items = e.form.option("items");

        let mainGroup = items.find(item => item.name === "mainGroup") ? items.find(item => item.name === "mainGroup").name : null;

        if (mainGroup != null) {
            form.option(
                "items[0].items[1].items[0].editorOptions.displayFormat",
                "dd/MM/yyyy HH:mm"
            );

            form.option(
                "items[0].items[1].items[2].editorOptions.displayFormat",
                "dd/MM/yyyy HH:mm"
            );
        }
    }

    const hidePopup = () => {
        setPopupVisible(false);
    }

    const closeButtonOptions = {
        text: 'Yes',
        onClick: hidePopup,
    };

    return (
        <>
            <h2 className={'content-block'}>Scheduler</h2>
            <LoadPanel
                shadingColor="rgba(0,0,0,0.4)"
                visible={loader}
                showIndicator={true}
            />

            <Popup
                height='auto'
                width={'auto'}
                visible={popupVisible}
                hideOnOutsideClick={false}
                showCloseButton={false}
                showTitle={false}
            >
                <Position
                    at="center"
                    my="center"
                    collision="fit"
                />

                <div className="popup-content">
                    Do you want to start using scheduled opening hours, and keep service closed otherwise?
                </div>
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={closeButtonOptions}
                />

            </Popup>

            <div className='widget-grid-logs'>
                <div className="dx-field">
                    <div className="dx-field-value">

                        <DropDownBox
                            dataSource={storeDataSource}
                            keyExpr="id"
                            displayExpr="text"
                            disabled={storeDataSource != null && storeDataSource.length === 1 ? true : false}
                            ref={storeDDBRef}
                            value={selectedStore}
                            acceptCustomValue={true}
                            placeholder="Select a stores...">
                            <List
                                selectedItemKeys={storeDataSource != null && storeDataSource.length === 1 ? getDefaultStoreId() : {}}
                                selectionMode="single"
                                dataSource={storeDataSource}
                                onSelectionChanged={onStoreChanged}
                            />
                        </DropDownBox>
                    </div>
                </div>

                <div className="dx-field">
                    <div className="dx-field-value">
                        <DropDownBox
                            dataSource={deviceDataSource}
                            ref={dropDownBoxRef}
                            value={selectedDevice}
                            acceptCustomValue={true}
                            placeholder="Select a devices...">
                            <List

                                selectionMode="single"
                                onSelectionChanged={onDeviceChanged}
                                dataSource={deviceDataSource}
                            />
                        </DropDownBox>
                    </div>
                </div>
            </div>

            <Scheduler
                dataSource={dataSource}
                views={views}
                timeCellTemplate={onTimeCellRender}
                defaultCurrentView="week"
                defaultCurrentDate={currentDate}
                height={'50%'}
                startDayHour={1}
                endDayHour={24}
                showAllDayPanel={true}
                startDateExpr="start"
                endDateExpr="end"
                textExpr="subject"
                appointmentComponent={SchedulerViewTemplate}
                onAppointmentFormOpening={onAppointmentFormOpening}
                onAppointmentAdded={onAppointmentAdded}
                onAppointmentUpdated={onAppointmentUpdated}
                onAppointmentDeleted={onAppointmentDeleted}
                onAppointmentAdding={onAppointmentAdding}
                onAppointmentUpdating={onAppointmentUpdating}
                recurrenceRuleExpr="recurrenceRule"
                recurrenceExceptionExpr="recurrenceException" >
            </Scheduler>
        </>
    )
}

export { SchedulerPage };