import { apiProvider } from '../utilities/provider';
import { ApiUrlConstants } from './apiUrl.constants';

export const logsService = {
    getLogs,
    getUnlockTypes,
    getUnlockStatus,
    getLog,
    GetRecent
};

async function getLogs(deviceId, storeId, params) {
    return await apiProvider.fetch.getAll(ApiUrlConstants.AccessControl.LOGS + params + "&deviceId=" + deviceId + "&storeId=" + storeId);
}

async function getUnlockTypes() {
    return await apiProvider.fetch.getAll(ApiUrlConstants.AccessControl.UNLOCKTYPE_LOOKUP);
}

async function getUnlockStatus() {
    return await apiProvider.fetch.getAll(ApiUrlConstants.AccessControl.UNLOCKSTATUS_LOOKUP);
}

async function getLog(logId) {
    return await apiProvider.fetch.getAll(ApiUrlConstants.AccessControl.LOGS + "/" + logId);
}

async function GetRecent(params) {
    return await apiProvider.fetch.getAll(ApiUrlConstants.AccessControl.GETRECENT + params);
}