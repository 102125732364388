import { apiProvider } from '../utilities/provider';
import { ApiUrlConstants } from './apiUrl.constants';

export const VippsMerchantService = {
    getAllVippsMerchants,
    deleteVippsMerchant,
    addVippsMerchant,
    updateVippsMerchant,
    getVippsMerchant 
};

async function getAllVippsMerchants() {
    return await apiProvider.fetch.getAll(ApiUrlConstants.AccessControl.VippsMerchant);
}

async function deleteVippsMerchant(id) {
    let path = ApiUrlConstants.AccessControl.VippsMerchant;
    return await apiProvider.fetch.remove(path, id);
}

async function addVippsMerchant(vippsMerchant){
    return await apiProvider.fetch.post(ApiUrlConstants.AccessControl.VippsMerchant, vippsMerchant);
}

async function updateVippsMerchant(vippsMerchant) {
    return await apiProvider.fetch.put(ApiUrlConstants.AccessControl.VippsMerchant, vippsMerchant);
}

async function getVippsMerchant(id) {
    return await apiProvider.fetch.getSingle(ApiUrlConstants.AccessControl.VippsMerchant, id, false);
}


