import 'devextreme/data/odata/store';
import { schedulerService } from '../../services/api/scheduler-service';

export async function schedulerDataSource(deviceId) {
    var argument = "deviceId=" + deviceId;
    let taskData = await schedulerService.getAll(argument);
    return taskData;
}

export async function createSchduler(argument) {
    let taskData = await schedulerService.createScheduler(argument);
    return taskData;
}

export async function updateSchduler(argument) {
    let taskData = await schedulerService.update(argument);
    return taskData;
}

export async function deleteSchduler(id) {
    let taskData = await schedulerService.remove(id);
    return taskData;
}

export const formatOptions = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric'
};