export const localStorageService = {
    getAuthToken,
    getEmail,
    setAuthToken,
    setEmail,
    clearStorage,
    setStoreId,
    getStoreId,
    getRoleName,
    setRoleName,
    getStoreName,
    setStoreName,
    setIsChecked,
    getIsChecked,
    getChainName,
    setChainName,
}

function clearStorage() {
    localStorage.removeItem('authToken');
    localStorage.removeItem('storeId');
    localStorage.removeItem('storeName');
    localStorage.removeItem('roleName');

    if (!getIsChecked()) {
        localStorage.removeItem('email');
        localStorage.removeItem('chainName');
        localStorage.removeItem('isChecked');
    }
}

function getAuthToken() {
    return localStorage.getItem('authToken');
}

function getEmail() {
    return localStorage.getItem('email');
}

function setAuthToken(authToken) {
    return localStorage.setItem('authToken', authToken);
}

function setEmail(email) {
    return localStorage.setItem('email', email);
}

function getStoreId() {
    return localStorage.getItem('storeId');
}

function setStoreId(storeId) {
    return localStorage.setItem('storeId', storeId);
}

function getRoleName() {
    return localStorage.getItem('roleName');
}

function setRoleName(name) {
    return localStorage.setItem('roleName', name);
}

function getStoreName() {
    return localStorage.getItem('storeName');
}

function setStoreName(name) {
    return localStorage.setItem('storeName', name);
}

function getIsChecked() {
    return JSON.parse(localStorage.getItem('isChecked'));
}

function setIsChecked(isChecked) {
    return localStorage.setItem('isChecked', JSON.stringify(isChecked));
}

function getChainName() {
    return localStorage.getItem('chainName');
}

function setChainName(chainName) {
    return localStorage.setItem('chainName', chainName);
}