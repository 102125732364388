import React from 'react';

export default function SchedulerViewTemplate(model) {
    const { targetedAppointmentData } = model.data; 

    return (
        <div className="showtime-preview">
            <div>
                <strong>{targetedAppointmentData.subject}</strong>
            </div>
            <div>
                {new Date(targetedAppointmentData.start).toLocaleTimeString('default', { hour: '2-digit', minute: '2-digit' })}
                {' - '}
                {new Date(targetedAppointmentData.end).toLocaleTimeString('default', { hour: '2-digit', minute: '2-digit' })}
            </div>
        </div>
    );
}