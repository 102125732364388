import notify from 'devextreme/ui/notify';

export function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                handleResponseError(response);
            }
            const error = (data && data.message) || response.statusText;
            toastPopUp(error);
            return Promise.reject(error);
        }

        return data;
    }).catch(error => {
        handleError(error);
        return Promise.reject(error);
    });
}

export function handleAxiosResponse(response) {
    try {
        if (!response.status === 200) {
            handleResponseError(response);
            const error = response.statusText;
            return Promise.reject(error);
        }

        return response.data;
    }
    catch (error) {
        handleError(error);
        return Promise.reject(error);
    }
}

function handleResponseError(response) {
    window.location.reload(true);
    console.log("HTTP error, status = " + response.status + ", status text = " + response.statusText);
}

function handleError(error) {
    console.log(error);
}

function toastPopUp(message) {
    notify(message, 'warning', 2000);
}