import 'devextreme/data/odata/store';
import CustomStore from 'devextreme/data/custom_store';
import { VippsMerchantService } from '../../services/api/vipps-merchant-service';
import { toast } from '../../helper/notifyBox';

export function vippsMerchantDataSource() {
    return {
        store: new CustomStore({
            key: 'id',
            load: async (loadOptions) => {
                let params = '?';
                [
                    'skip',
                    'take',
                    'requireTotalCount',
                    'requireGroupCount',
                    'sort',
                    'filter',
                    'totalSummary',
                    'group',
                    'groupSummary'
                ].forEach(function (i) {
                    if (i in loadOptions && loadOptions[i]) {
                        params += `${i}=${JSON.stringify(loadOptions[i])}&`;
                    }
                });
                params = params.slice(0, -1);

                let taskData = await VippsMerchantService.getAllVippsMerchants(params);
                return {
                    data: taskData.data,
                    totalCount: taskData.totalCount,
                    summary: taskData.summary,
                    groupCount: taskData.groupCount
                };


            },
            insert: async (values) => {
                if (values != null) {
                    let response = await VippsMerchantService.addVippsMerchant(values);
                    if (response === true)
                        toast("Vipps Merchant is created ", "success");
                }
            },

            update: async (key, values) => {
                values.id = key;
                if (key > 0) {
                    var store = await VippsMerchantService.getVippsMerchant(key);
                    if (store != null) {
                        var updateInfo = {
                            ...store,
                            ...values
                        }
                        let response = await VippsMerchantService.updateVippsMerchant(updateInfo);
                        if (response === true)
                            toast("Vipps Merchant is updated ", "success");
                    }
                }
            },
            remove: async (key) => {
                if (key > 0) {
                    var response = await VippsMerchantService.deleteVippsMerchant(key);
                    if (response === true)
                        toast("Vipps Merchant is deleted ", "success");
                }
            },
        })
    }
}

