import { handleResponse } from './response';
import { localStorageService } from '../../services/utilities/localStorageService';

function errorHandler(error) {
    return error;
}

function getAuth(){
    return 'Bearer ' + localStorageService.getAuthToken();
}

async function readJSON(url) {
    return await fetch(url).then(handleResponse).catch(errorHandler);
}

async function getAll(url) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': getAuth()
        }
    };

    return await fetch(url, requestOptions).then(handleResponse).catch(errorHandler);
}

async function getSingle(url, id, flagQuery) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': getAuth()
        }
    };

    if (flagQuery) {
        return await fetch(`${url}${id}`, requestOptions).then(handleResponse).catch(errorHandler);;
    } else {
        return await fetch(`${url}/${id}`, requestOptions).then(handleResponse).catch(errorHandler);;
    }
}

async function post(url, model) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': getAuth()
        },
        body: JSON.stringify(model)
    };
    return await fetch(url, requestOptions).then(handleResponse).catch(errorHandler);

}

async function put(url, model) {
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': getAuth()
        },
        body: JSON.stringify(model)
    };

    return await fetch(url, requestOptions).then(handleResponse).catch(errorHandler);;

}

async function remove(url, id) {
    const requestOptions = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': getAuth()
        },
    };

    return await fetch(`${url}/${id}`, requestOptions).then(handleResponse).catch(errorHandler);;

}

export const fetchProvider = {
    getAll,
    getSingle,
    post,
    put,
    remove,
    readJSON
};