import React, { useEffect, useState, useRef, useCallback } from 'react';
import DataGrid, {
    Column,
    FilterRow,
    Lookup,
    Scrolling,
    SearchPanel
} from 'devextreme-react/data-grid';
import LoadPanel from 'devextreme-react/load-panel';
import { interactLogsDataSource } from './interactLogsDataSource';
import { logsService } from '../../services/api/log-service';
import { deviceService } from '../../services/api/device-service';
import { storeService } from '../../services/api/store-service';
import { localStorageService } from '../../services/utilities/localStorageService';
import { roleEnum } from '../../helper/enumTypes';
import LogUserPopUp from './logUser-popUp';
import SelectBox from 'devextreme-react/select-box';

function InteractLogsPage() {
    const [dataSource, setDataSource] = useState({});
    const [loader, setLoader] = useState(false);
    const [storeDataSource, setStoreDataSource] = useState();
    const [selectedStore, setSelectedStore] = useState();
    const [deviceDataSource, setDeviceDataSource] = useState();
    const [selectedDevice, setSelectedDevice] = useState();
    const [unlockTypeDataSource, setUnlockTypeDataSource] = useState();
    const [unlockStatusDataSource, setUnlockStatusDataSource] = useState();
    const dataGridRef = useRef(null);
    const [selectedGridRow, setSelectedGridRow] = useState({});
    const storeLabel = { 'aria-label': "Select a stores..." };
    const deviceLabel = { 'aria-label': "Select a devices..." };

    useEffect(() => {
        initLookUps();
    }, []);

    const initLookUps = async () => {
        setLoader(true);
        var responseStores = await storeService.getStoresLookUp();
        setStoreDataSource(responseStores);

        var responseUnlockType = await logsService.getUnlockTypes();
        setUnlockTypeDataSource(responseUnlockType);

        var responseUnlockStatus = await logsService.getUnlockStatus();
        setUnlockStatusDataSource(responseUnlockStatus);
        setLoader(false);
    }

    const setGridData = async (deviceId, storeId) => {
        setLoader(true);
        await setDataSource(interactLogsDataSource(deviceId, storeId));
        setLoader(false);
    }

    const onStoreChanged = async (arg) => {
        var store = arg.value;
        if (store) {
            setDataSource([]);
            setSelectedStore(store);
            var responseDevices = await deviceService.getDeviceLookUp(store.id);
            if (responseDevices.length > 0) {
                responseDevices.unshift({ 'id': 0, 'text': 'All' });
            }
            setDeviceDataSource(responseDevices);
            setSelectedDevice(responseDevices[0]);
            if (responseDevices.length === 0) {
                resetData();
            }
        };
    }

    const onDeviceChanged = async (arg) => {
        var device = arg.value;
        if (device) {
            setSelectedDevice(device);
            if (selectedStore != null) {
                var store = storeDataSource.find(x => x.id === selectedStore.id);
                await setGridData(device.id, store.id);
            }
        } else {
            resetData();
        }
    };

    const resetData = () => {
        setSelectedDevice('');
        setDataSource([]);
    }

    const selectFirstRow = async (e) => {
        if (e.data && e.data.id > 0) {
            var userLog = await logsService.getLog(e.data.id);
            setSelectedGridRow(userLog);
        }
    };

    const getDefaultStoreId = async () => {
        var roleName = localStorageService.getRoleName();
        if (roleName === roleEnum.admin) {
            return [];
        }

        var storeID = localStorageService.getStoreId();
        if (storeDataSource != null) {
            var store = storeDataSource.find(x => x.id === parseInt(storeID));
            if (store != null && selectedStore == null) {
                var arg = {
                    addedItems: [
                        store
                    ]
                }
                onStoreChanged(arg);
                return store;
            }
        }
        return [];
    }

    return (
        <>
            <h2 className={'content-block'}>Logs</h2>
            <LoadPanel
                shadingColor="rgba(0,0,0,0.4)"
                visible={loader}
                showIndicator={true}
            />

            <div className='widget-grid-logs'>
                <div className="dx-field">
                    <div className="dx-field-value">
                        <SelectBox
                            items={storeDataSource}
                            value={storeDataSource?.length === 1 ? storeDataSource[0] : selectedStore}
                            selectedItem={selectedStore}
                            displayExpr='text'
                            inputAttr={storeLabel}
                            disabled={storeDataSource != null && storeDataSource.length === 1 ? true : false}
                            onValueChanged={onStoreChanged} />
                    </div>
                </div>

                <div className="dx-field">
                    <div className="dx-field-value">
                        <SelectBox
                            items={deviceDataSource}
                            value={selectedDevice}
                            selectedItem={selectedDevice}
                            displayExpr='text'
                            inputAttr={deviceLabel}
                            onValueChanged={onDeviceChanged} />
                    </div>
                </div>
            </div>


            <DataGrid
                ref={dataGridRef}
                height={600}
                className={'dx-card wide-card'}
                dataSource={dataSource}
                showBorders={false}
                columnHidingEnabled={true}
                selection={{ mode: 'single' }}
                remoteOperations={true}
                width={'100%'}
                onCellClick={selectFirstRow}
                repaintChangesOnly={true}
            >

                <Scrolling mode="virtual" rowRenderingMode="virtual" />
                <SearchPanel visible={true} width={610} />
                <Column
                    dataField={'datetime'}
                    caption={'Date'}
                    width={160}
                    dataType="date"
                    format={"dd.MM.yy HH:mm:ss"} />


                <Column
                    dataField={'deviceID'}
                    caption={'Device'}
                    width={310}>

                    <Lookup
                        dataSource={deviceDataSource}
                        valueExpr={'id'}
                        displayExpr={'text'}
                    />
                </Column>

                <Column
                    dataField={'deviceID'}
                    caption={'FriendlyName'}
                    width={110}>

                    <Lookup
                        dataSource={deviceDataSource}
                        valueExpr={'id'}
                        displayExpr={'value'}
                    />
                </Column>

                <Column
                    dataField={'userName'}
                    caption={'User Name'}
                    width={150} />

                <Column
                    dataField={'emailAddress'}
                    caption={'User Email'}
                    width={150} />

                <Column
                    dataField={'dob'}
                    caption={'Birth Date'}
                    width={130} />

                <Column
                    dataField={'mobileNo'}
                    caption={'Contact No.'}
                    width={130} />

                <Column
                    dataField={'unlockType'}
                    caption={'Unlock Type'}
                    width={130}>

                    <Lookup
                        dataSource={unlockTypeDataSource}
                        valueExpr={'id'}
                        displayExpr={'text'}
                    />
                </Column>


                <Column
                    dataField={'unlockStatus'}
                    caption={'Unlock Status'}
                    width={130}>

                    <Lookup
                        dataSource={unlockStatusDataSource}
                        valueExpr={'id'}
                        displayExpr={'text'}
                    />
                </Column>

                <Column
                    dataField={'payload'}
                    caption={'Payload'}
                    width={190}
                />

            </DataGrid>


            <LogUserPopUp userSelected={selectedGridRow} setUserPopUp={true} ></LogUserPopUp>
        </>
    )
}

export { InteractLogsPage };