import { apiProvider } from '../utilities/provider';
import { ApiUrlConstants } from './apiUrl.constants';

export const blacklistService = {
    getAllBlacklists,
    addBlacklist,
    updateBlacklist,
    getBlacklist,
    deleteBlacklist,
    getStoreLookUp,
    getDeviceLookUp
};

async function getAllBlacklists(storeId, deviceId) {
    return await apiProvider.fetch.getAll(ApiUrlConstants.AccessControl.BLACKLIST + "/" + storeId + "/" + deviceId);
}

async function deleteBlacklist(id) {
    let path = ApiUrlConstants.AccessControl.BLACKLIST;
    return await apiProvider.fetch.remove(path, id);
}

async function addBlacklist(blacklist){
    return await apiProvider.fetch.post(ApiUrlConstants.AccessControl.BLACKLIST, blacklist);
}

async function updateBlacklist(blacklist) {
    return await apiProvider.fetch.put(ApiUrlConstants.AccessControl.BLACKLIST, blacklist);
}

async function getBlacklist(id) {
    return await apiProvider.fetch.getSingle(ApiUrlConstants.AccessControl.BLACKLIST, id, false);
}

async function getStoreLookUp() {
    return await apiProvider.fetch.getAll(ApiUrlConstants.AccessControl.STORE_LOOKUP);
}

async function getDeviceLookUp(id) {
    return await apiProvider.fetch.getSingle(ApiUrlConstants.AccessControl.DEVICE_LOOKUP, id);
}

