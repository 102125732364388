import notify from 'devextreme/ui/notify';

const defaultTimeOut = 5000;

export const toast = (message, infoType) => {
    notify({
        message: message,
        position: {
            my: 'center top',
            at: 'center top'
        }
    }, infoType, defaultTimeOut);
}