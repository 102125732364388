import { apiProvider } from '../utilities/provider';
import { ApiUrlConstants } from './apiUrl.constants';

export const schedulerService = {
    getAll,
    createScheduler,
    update,
    remove
};

async function getAll(params) {
    return await apiProvider.fetch.getSingle(ApiUrlConstants.AccessControl.SCHEDULER, "?" + params, true);
}

async function createScheduler(scheduler) {
    return await apiProvider.fetch.post(ApiUrlConstants.AccessControl.SCHEDULER, scheduler);
}

async function update(scheduler) {
    return await apiProvider.fetch.put(ApiUrlConstants.AccessControl.SCHEDULER, scheduler);
}

async function remove(id) {
    return await apiProvider.fetch.remove(ApiUrlConstants.AccessControl.SCHEDULER, id);
}