import React, { useEffect, useState, useRef, useCallback } from 'react';
import DataGrid, {
    Column,
    Editing,
    Lookup,
    Scrolling
} from 'devextreme-react/data-grid';
import LoadPanel from 'devextreme-react/load-panel';
import { blacklistDataSource } from './blacklistDataSource';
import { localStorageService } from '../../services/utilities/localStorageService';
import { deviceService } from '../../services/api/device-service';
import { storeService } from '../../services/api/store-service';
import { roleEnum } from '../../helper/enumTypes';
import SelectBox from 'devextreme-react/select-box';

function BlackListsPage() {
    const [dataSource, setDataSource] = useState({});
    const [loader, setLoader] = useState(false);
    const [storeDataSource, setStoreDataSource] = useState();
    const [selectedStore, setSelectedStore] = useState();
    const [deviceDataSource, setDeviceDataSource] = useState();

    const [selectedDevice, setSelectedDevice] = useState();
    const dataGridRef = useRef(null);
    const storeLabel = { 'aria-label': "Select a stores..." };
    const deviceLabel = { 'aria-label': "Select a devices..." };

    useEffect(() => {
        initLookUps();
    }, []);

    const initLookUps = async () => {
        setLoader(true);
        var responseStores = await storeService.getStoresLookUp();
        setStoreDataSource(responseStores);
        setLoader(false);
    }

    const setGridData = async (storeId, deviceId) => {
        setLoader(true);
        await setDataSource(blacklistDataSource(storeId, deviceId));
        setLoader(false);
    }

    const onStoreChanged = async (arg) => {
        var store = arg.value;
        if (store) {
            setDataSource([]);
            setSelectedStore(store);
            var responseDevices = await deviceService.getDeviceLookUp(store.id);
            if (responseDevices.length > 0) {
                responseDevices.unshift({ 'id': 0, 'text': 'All' });
            }
            setDeviceDataSource(responseDevices);
            setSelectedDevice(responseDevices[0]);
            if (responseDevices.length === 0) {
                resetData();
            }
        }
    };

    const onDeviceChanged = (async (arg) => {
        var device = arg.value;
        if (device) {
            setSelectedDevice(device);
            if (selectedStore != null) {
                var store = storeDataSource.find(x => x.id === selectedStore.id);
                await setGridData(store.id, device.id);
            }
        } else {
            resetData();
        }
    });

    const resetData = () => {
        setSelectedDevice('');
        setDataSource([]);
    }

    const getDefaultStoreId = async () => {
        var roleName = localStorageService.getRoleName();
        if (roleName === roleEnum.admin) {
            return {};
        }

        var storeID = localStorageService.getStoreId();
        if (storeDataSource != null) {
            var store = storeDataSource.find(x => x.id === parseInt(storeID));
            if (store != null && selectedStore == null) {
                var arg = {
                    value: store
                }
                await onStoreChanged(arg);
                return {};
            }
        }
        return {};
    }

    return (
        <>
            <h2 className={'content-block'}>Black List</h2>
            <LoadPanel
                shadingColor="rgba(0,0,0,0.4)"
                visible={loader}
                showIndicator={true}
            />

            <div className='widget-grid-logs'>
                <div className="dx-field">
                    <div className="dx-field-value">
                        <SelectBox
                            items={storeDataSource}
                            value={storeDataSource?.length === 1 ? storeDataSource[0] : selectedStore}
                            selectedItem={selectedStore}
                            displayExpr='text'
                            inputAttr={storeLabel}
                            disabled={storeDataSource != null && storeDataSource.length === 1 ? true : false}
                            onValueChanged={onStoreChanged} />
                    </div>
                </div>

                <div className="dx-field">
                    <div className="dx-field-value">
                        <SelectBox
                            items={deviceDataSource}
                            value={selectedDevice}
                            selectedItem={selectedDevice}
                            displayExpr='text'
                            inputAttr={deviceLabel}
                            onValueChanged={onDeviceChanged} />
                    </div>
                </div>
            </div>
            <DataGrid
                ref={dataGridRef}
                height={600}
                className={'dx-card wide-card'}
                dataSource={dataSource}
                showBorders={false}
                columnHidingEnabled={true}
                selection={{ mode: 'single' }}
                remoteOperations={true}
                width={'100%'}
                repaintChangesOnly={true}
            >
                <Scrolling mode="virtual" rowRenderingMode="virtual" />

                <Editing
                    mode="row"
                    allowAdding={true}
                    allowUpdating={true}
                    allowDeleting={true}
                />

                <Column
                    dataField={'deviceID'}
                    caption={'Device'}
                    width={310}>
                    <Lookup
                        dataSource={deviceDataSource}
                        valueExpr={'id'}
                        displayExpr={'text'}
                    />
                </Column>
                <Column
                    allowEditing={false}
                    dataField={'deviceID'}
                    caption={'FriendlyName'}
                    width={220}>
                    <Lookup
                        dataSource={deviceDataSource}
                        valueExpr={'id'}
                        displayExpr={'value'}
                    />
                </Column>
                <Column
                    dataField={'vippsSid'}
                    width={100}
                    caption={'Vipps Sid'}
                />
                <Column
                    dataField={'vippsPhoneNumber'}
                    width={250}
                    caption={'Vipps Phone Number'}
                >
                </Column>
                <Column
                    dataField={'smsPhoneNumber'}
                    width={250}
                    caption={'Sms Phone Number'}
                >
                </Column>
                <Column
                    dataField={'expire'}
                    caption={'Expire Date'}
                    width={150}
                    dataType="date"
                    format={"dd.MM.yy"} />
                <Column
                    allowEditing={false}
                    dataField={'addedBy'}
                    caption={'Added by'}
                    allowSorting={false}
                    width={100}
                />
            </DataGrid>
        </>
    )
}

export { BlackListsPage };