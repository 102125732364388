import { apiProvider } from '../utilities/provider';
import { ApiUrlConstants } from './apiUrl.constants';

export const storeService = {
    getAllStores,
    addStore,
    deleteStore,
    updateStore,
    getStore,
    getStoresLookUp
};

async function getAllStores(params) {
    return await apiProvider.fetch.getAll(ApiUrlConstants.AccessControl.STORE + params);
}

async function deleteStore(id) {
    let path = ApiUrlConstants.AccessControl.STORE;
    return await apiProvider.fetch.remove(path, id);
}

async function addStore(store) {
    return await apiProvider.fetch.post(ApiUrlConstants.AccessControl.STORE, store);
}

async function updateStore(store) {
    return await apiProvider.fetch.put(ApiUrlConstants.AccessControl.STORE, store);
}

async function getStore(id) {
    return await apiProvider.fetch.getSingle(ApiUrlConstants.AccessControl.STORE, id, false);
}

async function getStoresLookUp() {
    return await apiProvider.fetch.getAll(ApiUrlConstants.AccessControl.STORE_LOOKUP);
}