import React, { useEffect, useState, useCallback } from 'react';
import DataGrid, {
    Column,
    FilterRow,
    Lookup,
    RequiredRule,
    Editing,
    Scrolling,
    ColumnChooser
} from 'devextreme-react/data-grid';
import LoadPanel from 'devextreme-react/load-panel';
import { deviceDataSource } from './deviceDataSource';
import { deviceTypeService } from '../../services/api/device-type-service';
import { storeService } from '../../services/api/store-service';
import { localStorageService } from '../../services/utilities/localStorageService';
import { roleEnum } from '../../helper/enumTypes';
import Button from 'devextreme-react/button';
import { toast } from '../../helper/notifyBox';
import { deviceService } from '../../services/api/device-service';


const DevicesPage = () => {
    const [dataSource, setDataSource] = useState({});
    const [loader, setLoader] = useState(false);
    const requiredInput = 'This field is required';
    const [deviceTypeDS, setDeviceTypeDS] = useState([]);
    const [storeDS, setStoreDS] = useState([]);

    useEffect(() => {
        setGridData();
    }, []);


    const setGridData = async () => {
        setLoader(true);
        var response = await deviceTypeService.getDeviceTypeLookUp();
        if (response) {
            setDeviceTypeDS(response);
        }
        var stores = await storeService.getStoresLookUp();
        if (stores) {
            setStoreDS(stores);
        }
        await setDataSource(deviceDataSource());
        setLoader(false);
    }

    function renderMqtttriggers(data) {
        return (
            <div className="dx-command-edit dx-command-edit-with-icons dx-cell-focus-disabled">
                <a onClick={(e) => triggerMqttDevice(data, true)} className="dx-link dx-link-edit on-icon-edit onImgButton" title="på" aria-label="på"></a>
                <a onClick={(e) => triggerMqttDevice(data, false)} className="dx-link dx-link-edit off-icon-edit offImgButton" title="av" aria-label="av"></a>
                <a onClick={(e) => triggerMqttDevice(data,'')} className="dx-link dx-link-edit trigger-icon-edit  triggerImgButton" title="avtrekker" aria-label="avtrekker"></a>
            </div>
        );
    }

    const triggerMqttDevice = async (data, deviceStatus) => {
        setLoader(true);
        var updateInfo = {
            DeviceStatus: deviceStatus,
            DeviceUUID: data.data.deviceUUID
        }
        var response = await deviceService.triggerMqttDevice(updateInfo)
        if (response == true) {
            toast("Enheten er utløst", "success");
            setLoader(false);
            return;
        }
        toast("kan ikke utløse enheten", "error");
        setLoader(false);
    };

    return (
        <>
            <h2 className={'content-block'}>Device</h2>
            <LoadPanel
                shadingColor="rgba(0,0,0,0.4)"
                visible={loader}
                showIndicator={true}
            />

            <DataGrid
                height={600}
                className={'dx-card wide-card'}
                dataSource={dataSource}
                showBorders={false}
                columnMinWidth={100}
                columnAutoWidth={true}
                columnHidingEnabled={true}
                selection={{ mode: 'single' }}
                remoteOperations={true}
                showRowLines={true}
            >
                <FilterRow visible={true} />
                <ColumnChooser
                    enabled={true}
                    mode={'row'}
                >
                </ColumnChooser>
                <Scrolling mode="virtual" rowRenderingMode="virtual" />

                <Editing
                    mode="row"
                    allowAdding={true}
                    allowUpdating={true}
                    allowDeleting={true}
                />

                <Column
                    dataField={'deviceTypeID'}
                    caption={'Device Type'}
                    width={150}
                >
                    <Lookup
                        dataSource={deviceTypeDS}
                        valueExpr={'id'}
                        displayExpr={'text'}
                    />
                    <RequiredRule
                        message={'Please select a device type'}
                    />
                </Column>

                {
                    localStorageService.getRoleName().toLowerCase() !== roleEnum.admin
                        ?
                        ''
                        :
                        <Column
                            dataField={'storeId'}
                            caption={'Store'}
                            width={150}
                        >
                            <Lookup
                                dataSource={storeDS}
                                valueExpr={'id'}
                                displayExpr={'text'}
                            />
                            <RequiredRule
                                message={'Please select a store'}
                            />
                        </Column>
                }


                <Column
                    dataField={'deviceUUID'}
                    caption={'Device Unique Number'}
                    width={290}
                >

                    <RequiredRule
                        message={requiredInput}
                    />
                </Column>

                <Column
                    dataField={'friendlyName'}
                    caption={'Friendly Name'}
                    width={120}
                />

                <Column
                    dataField={'geoCoordinates'}
                    caption={'GeoCoordinates'}
                    width={120}
                >
                </Column>

                <Column
                    dataField={'geoMandatory'}
                    caption={'GeoMandatory'}
                    width={120}
                />

                <Column
                    dataField={'payload'}
                    caption={'Payload'}
                    width={200}
                />
                <Column
                    caption={'Mqtt action'}
                    cellRender={renderMqtttriggers}
                    visible={true}
                >
                </Column>

                <Column
                    dataField={'topic'}
                    caption={'Topic'}
                    width={200}
                />


                <Column
                    dataField={'description'}
                    caption={'Description'}
                    width={150}
                />

                <Column
                    dataField={'allowSmsCode'}
                    caption={'Enable SMS'}
                    width={70}
                />

                <Column
                    dataField={'deviceCode'}
                    caption={'DeviceCode'}
                    width={100}
                />

                <Column
                    dataField={'endOfLife'}
                    caption={'EndOfLife'}
                    dataType='date'
                    width={70}
                    visible={false}
                />

                <Column
                    dataField={'active'}
                    caption={'Active'}
                    visible={false}
                />

                <Column
                    dataField={'instruction'}
                    caption={'Instruction'}
                    width={70}
                    visible={false}
                />

                {/* <Column
                    dataField={'restrictionID'}
                    caption={'Restriction'}
                    width={70}
                /> */}

                <Column
                    dataField={'retainRelay'}
                    caption={'Retain Relay'}
                    width={70}
                    visible={false}
                />
                <Column
                    dataField={'reverseRelay'}
                    caption={'Reverse Relay'}
                    width={70}
                    visible={false}
                />
                <Column
                    dataField={'timeout'}
                    caption={'Timeout'}
                    dataType='number'
                    width={70}
                    visible={false}
                />
            </DataGrid>
        </>
    )
}

export { DevicesPage };