import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Popup, Position, ToolbarItem } from 'devextreme-react/popup';

function LogUserPopUp({ userSelected, setUserPopUp }) {
    const [selectedGridRow, setSelectedGridRow] = useState();
    const [show, setShow] = useState(false);

    useEffect(() => {
        if (setUserPopUp && Object.keys(userSelected).length !== 0) {
            setSelectedGridRow(userSelected);
            setShow(setUserPopUp);
        }
    }, [userSelected]);


    const hidePopup = () => {
        setShow(false);
        setSelectedGridRow();
    };

    const closeButtonOptions = {
        text: 'Cancel',
        onClick: hidePopup,
    };

    return (
        <>
            <Popup
                height='auto'
                width={'550px'}
                visible={show}
                hideOnOutsideClick={false}
                showCloseButton={false}
                showTitle={false}
            >
                <Position
                    at="center"
                    my="center"
                    collision="fit"
                />

                <div className="popup-content">
                    <p><strong>User name : </strong>
                        {selectedGridRow?.userName || '--'}
                    </p>
                    <p><strong>Mobile number : </strong>
                        {selectedGridRow?.mobileNo || '--'}</p>
                    <p><strong>DOB : </strong>
                        {selectedGridRow?.dob ? new Date(selectedGridRow?.dob).toLocaleDateString('no-No', { day: '2-digit', month: '2-digit', year: 'numeric' }) : '--'}
                    </p>
                    <p> <strong>User email : </strong>
                        {selectedGridRow?.emailAddress || '--'}
                    </p>
                    <p> <strong>Other description (payload) : </strong>
                        {selectedGridRow?.payload || '--'}
                    </p>
                </div>

                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={closeButtonOptions}
                />
            </Popup>
        </>
    );
}

export default LogUserPopUp;