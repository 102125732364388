import { HomePage, StoresPage, ProfilePage, DevicesPage, BlackListsPage, InteractLogsPage, SchedulerPage, VippsMerchantPage } from '../pages';
import { withNavigationWatcher } from '../contexts/navigation';

const routes = [
    {
        path: '/stores',
        element: StoresPage
    },
    {
        path: '/profile',
        element: ProfilePage
    },
    {
        path: '/home',
        element: HomePage
    },
    {
        element: DevicesPage,
        path: '/devices'
    },
    {
        element: BlackListsPage,
        path: '/blacklist'
    },
    {
        element: InteractLogsPage,
        path: '/interactlogs'
    },
    {
        element: SchedulerPage,
        path: '/scheduler'
    },
    {
        element: VippsMerchantPage,
        path: '/vippsMerchant'
    }
];

export default routes.map(route => {
    return {
        ...route,
        element: withNavigationWatcher(route.element, route.path)
    };
});
