import React, { useEffect, useState } from 'react';
import DataGrid, {
  Column,
  FilterRow,
  Lookup,
  RequiredRule,
  Editing,
  Scrolling
} from 'devextreme-react/data-grid';
import LoadPanel from 'devextreme-react/load-panel';
import { storesDataSource } from './storesDataSource';
import { chainService } from '../../services/api/chain-service';

function StoresPage() {
  const [dataSource, setDataSource] = useState({});
  const [loader, setLoader] = useState(false);
  const requiredInput = 'This field is required';
  const [chainDS, setChainDS] = useState([]);
  const chainID = 1; // use local storage after login api is mapped.

  useEffect(() => {
    setGridData();
  }, []);


  const setGridData = async () => {
    setLoader(true);
    var response = await chainService.getChainLookUp();
    if (response) {
      setChainDS(response);
    }

    await setDataSource(storesDataSource(chainID));
    setLoader(false);
  }


  return (
    <>
      <h2 className={'content-block'}>Stores</h2>
      <LoadPanel
        shadingColor="rgba(0,0,0,0.4)"
        visible={loader}
        showIndicator={true}
      />

      <DataGrid
        height={600}
        className={'dx-card wide-card'}
        dataSource={dataSource}
        showBorders={false}
        columnAutoWidth={true}
        columnHidingEnabled={true}
        selection={{ mode: 'single' }}
        remoteOperations={true}
        allowColumnResizing={true}
      >
        <FilterRow visible={true} />
        <Scrolling mode="virtual" rowRenderingMode="virtual" /> 

        <Editing
          mode="row"
          allowAdding={true}
          allowUpdating={true}
          allowDeleting={true}
        />

        <Column
          dataField={'chainID'}
          caption={'Kjede navn'}
          width={150}
        >
          <Lookup
            dataSource={chainDS}
            valueExpr={'id'}
            displayExpr={'text'}
          />
          <RequiredRule
            message={'Please select a chain'}
          />
        </Column>

        <Column
          dataField={'name'}
          caption={'Butikknavn'}
          width={190}
        >
          <RequiredRule
            message={requiredInput}
          />
        </Column>
        <Column
          dataField={'organizationNo'}
          caption={'Org.nr.'}
          width={90}
        >
          <RequiredRule
            message={requiredInput}
          />
        </Column>

        <Column
          dataField={'posStoreID'}
          caption={'Pos butikk id'}
          dataType='number'
          width={70}
        />
        <Column
          dataField={'licenseID'}
          caption={'Lisensnr'}
          width={70}
        />
        <Column
          dataField={'adress'}
          caption={'Addresse'}
        >
          <RequiredRule
            message={requiredInput}
          />
        </Column>

        <Column
          dataField={'postCode'}
          caption={'Postnr.'}
          width={80}
        />
        <Column
          dataField={'city'}
          caption={'Poststed'}
        >
          <RequiredRule
            message={requiredInput}
          />
        </Column>
        <Column
          dataField={'contactEmail'}
          caption={'Support Epost'}
        />

        <Column
          dataField={'contactPhone'}
          caption={'Support Tlf.'}
        />
        <Column
          dataField={'contactInstructions'}
          caption={'Kontakt instruksjoner'}
        />
      </DataGrid>
    </>
  )
}

export { StoresPage };