export const UnlockTypeEnum = {
    ByAdmin: 1,
    ByVipps: 2,
    BySmsCode: 3,
    BySelfService: 4
}


export const roleEnum = {
    admin: 'admin',
    user: 'user'
}