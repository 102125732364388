import 'devextreme/data/odata/store';
import CustomStore from 'devextreme/data/custom_store';
import { logsService } from '../../services/api/log-service';

export function interactLogsDataSource(deviceId, storeId) {
    return {
        store: new CustomStore({
            key: 'id',
            load: async (loadOptions) => {
                let params = '?';
                [
                    'skip',
                    'take',
                    'requireTotalCount',
                    'requireGroupCount',
                    'sort',
                    'filter',
                    'totalSummary',
                    'group',
                    'groupSummary'
                ].forEach(function (i) {
                    if (i in loadOptions && loadOptions[i]) {
                        params += `${i}=${JSON.stringify(loadOptions[i])}&`;
                    }
                });
                params = params.slice(0, -1);
                let taskData = await logsService.getLogs(deviceId, storeId, params);
                return taskData;
            }
        })
    }
}
export function recentLogsDataSource() {
    return {
        store: new CustomStore({
            key: 'id',
            load: async (loadOptions) => {
                let params = '?';
                [
                    'skip',
                    'take',
                    'requireTotalCount',
                    'requireGroupCount',
                    'sort',
                    'filter',
                    'totalSummary',
                    'group',
                    'groupSummary'
                ]
                    .forEach(function (i) {
                        if (i in loadOptions && loadOptions[i]) {
                            if (i === 'take')
                                params += `${i}=${JSON.stringify(10)}&`;
                            else
                                params += `${i}=${JSON.stringify(loadOptions[i])}&`;
                        }
                    });
                let result = await logsService.GetRecent(params);
                return result;
            },

        })
    }
}

