import React, { useState, useEffect, useRef } from 'react';
import DataGrid, {
    Column, Paging, Lookup, Scrolling
} from 'devextreme-react/data-grid';
import { logsService } from '../../services/api/log-service';
import { localStorageService } from '../../services/utilities/localStorageService';
import { deviceService } from '../../services/api/device-service';
import LogUserPopUp from '../interactLogs/logUser-popUp';
import { recentLogsDataSource } from '../interactLogs/interactLogsDataSource';




function GetRecentLogs() {
    const [recentLogData, setRecentLogsData] = useState();
    const dataGridRef = useRef(null);
    const [deviceDataSource, setDeviceDataSource] = useState();
    const [unlockTypeDataSource, setUnlockTypeDataSource] = useState();
    const [unlockStatusDataSource, setUnlockStatusDataSource] = useState();
    const [selectedGridRow, setSelectedGridRow] = useState({});

    useEffect(() => {
        initLookUps();
    }, []);

    const initLookUps = async () => {
        var responseUnlockType = await logsService.getUnlockTypes();
        setUnlockTypeDataSource(responseUnlockType);

        var responseUnlockStatus = await logsService.getUnlockStatus();
        setUnlockStatusDataSource(responseUnlockStatus);
        setRecentLogsData(recentLogsDataSource);
        var responseDevices = await deviceService.getDeviceLookUp(localStorageService.getStoreId());
        if (responseDevices.length > 0) {
            responseDevices.unshift({ 'id': 0, 'text': 'All' });
        }
        setDeviceDataSource(responseDevices);
    }

    const selectFirstRow = async (e) => {
        if (e.data && e.data.id > 0) {
            var userLog = await logsService.getLog(e.data.id);
            setSelectedGridRow(userLog);
        }
    };
    return (
        <div style={{ 'padding': "10px" }}>
            <React.Fragment>
                <DataGrid
                    id="gridContainer"
                    dataSource={recentLogData}
                    allowColumnReordering={true}
                    showBorders={true}
                    remoteOperations={true}
                    ref={dataGridRef}
                    columnHidingEnabled={false}
                    repaintChangesOnly={true}
                    onCellClick={selectFirstRow}
                    height={325}
                    className='gridContainer'
                >
                    <Scrolling mode="virtual" />
                    <Paging enabled={true} />
                    <Column
                        dataField={'datetime'}
                        caption={'Date'}
                        width={160}
                        dataType="date"
                        format={"dd.MM.yy HH:mm:ss"} />

                    <Column
                        dataField={'deviceID'}
                        caption={'Device'}
                        width={110}>

                        <Lookup
                            dataSource={deviceDataSource}
                            valueExpr={'id'}
                            displayExpr={'text'}
                        />
                    </Column>
                    <Column
                        dataField={'deviceID'}
                        caption={'FriendlyName'}
                        width={110}>

                        <Lookup
                            dataSource={deviceDataSource}
                            valueExpr={'id'}
                            displayExpr={'value'}
                        />
                    </Column>
                    <Column
                        dataField={'userName'}
                        caption={'User Name'}
                        width={150} />

                    <Column
                        dataField={'emailAddress'}
                        caption={'User Email'}
                        width={150} />

                    <Column
                        dataField={'dob'}
                        caption={'Birth Date'}
                        width={130} />

                    <Column
                        dataField={'mobileNo'}
                        caption={'Contact No.'}
                        width={130} />

                    <Column
                        dataField={'unlockType'}
                        caption={'Unlock Type'}
                        width={130}>

                        <Lookup
                            dataSource={unlockTypeDataSource}
                            valueExpr={'id'}
                            displayExpr={'text'}
                        />
                    </Column>
                    <Column
                        dataField={'unlockStatus'}
                        caption={'Unlock Status'}
                        width={130}>

                        <Lookup
                            dataSource={unlockStatusDataSource}
                            valueExpr={'id'}
                            displayExpr={'text'}
                        />
                    </Column>

                    <Column
                        dataField={'payload'}
                        caption={'Payload'}
                        width={190}
                    />
                </DataGrid>
                <LogUserPopUp userSelected={selectedGridRow} setUserPopUp={true} ></LogUserPopUp>

            </React.Fragment>
        </div>
    );
}

export { GetRecentLogs };