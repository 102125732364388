import React, { useMemo } from 'react';
import { useNavigate } from "react-router-dom";
import ContextMenu, { Position } from 'devextreme-react/context-menu';
import List from 'devextreme-react/list';
import './UserPanel.scss';
import { localStorageService } from '../../services/utilities/localStorageService';

export default function UserPanel({ menuMode }) {
  const navigate = useNavigate();

  const signOut = () => {    
    localStorageService.clearStorage();
    window.location.reload();
  };

  const menuItems = useMemo(() => ([
    {
      text: 'Logout',
      icon: 'runner',
      onClick: signOut
    }
  ]), []);
  return (
    <div className={'user-panel'}>
      <div className={'user-info'}>
        <div className={'image-container'}>
          <div
            className={'user-image'} />
        </div>
        <div className={'user-name'}>{localStorageService.getEmail()}</div>
      </div>

      {menuMode === 'context' && (
        <ContextMenu
          items={menuItems}
          target={'.user-button'}
          showEvent={'dxclick'}
          width={210}
          cssClass={'user-menu'}
        >
          <Position my={{ x: 'center', y: 'top' }} at={{ x: 'center', y: 'bottom' }} />
        </ContextMenu>
      )}
      {menuMode === 'list' && (
        <List className={'dx-toolbar-menu-action'} items={menuItems} />
      )}
    </div>
  );
}
