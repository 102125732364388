import { getAppSettings } from '../../config';

const appSettings = getAppSettings();
const APIBaseUrl = appSettings.apiGateway.API_URL;
const APIDashboardBaseUrl = appSettings.apiGateway.DASHBOARD_URL;

export const ApiUrlConstants = {
    AccessControl: {
        LOGIN: APIBaseUrl + "/authenticate/auth",
        STORE: APIBaseUrl + "/Store",
        BLACKLIST: APIBaseUrl + "/BlackList",
        CHAIN_LOOKUP: APIBaseUrl + "/Chain/LookUp",
        LOGS: APIBaseUrl + "/Logs",
        DEVICE_LOOKUP: APIBaseUrl + "/Device/LookUp",
        STORE_LOOKUP: APIBaseUrl + "/Store/LookUp",
        UNLOCKTYPE_LOOKUP: APIBaseUrl + "/Logs/UnlockTypeLookUp",
        UNLOCKSTATUS_LOOKUP: APIBaseUrl + "/Logs/UnlockStatusLookUp",
        SCHEDULER: APIBaseUrl + "/Scheduler",
        DEVICE: APIBaseUrl + "/Device",
        DEVICE_TYPE_LOOKUP: APIBaseUrl + "/DeviceType/LookUp",
        VippsMerchant: APIBaseUrl + "/VippsMerchant",
        DASHBOARD: APIDashboardBaseUrl + "/dashboardApi",
        GETRECENT: APIBaseUrl + "/Logs/GetRecent",
        UPDATEDEVICEPAYLOAD: APIBaseUrl + "/Device/UpdateDevicePayload",
        TRIGGERMQTTDEVICE: APIBaseUrl + "/Device/TriggerMqttDevice"
    }
}