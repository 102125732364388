import { apiProvider } from '../utilities/provider';
import { ApiUrlConstants } from './apiUrl.constants';

export const deviceService = {
    getDeviceLookUp,
    getAllDevices,
    deleteDevice,
    addDevice,
    updateDevice,
    getDevice,
    updateDevicePayload,
    triggerMqttDevice
};

async function getDeviceLookUp(storeID) {
    return await apiProvider.fetch.getAll(ApiUrlConstants.AccessControl.DEVICE_LOOKUP + "/" + storeID);
}

async function getAllDevices(params) {
    return await apiProvider.fetch.getAll(ApiUrlConstants.AccessControl.DEVICE + params);
}

async function deleteDevice(id) {
    let path = ApiUrlConstants.AccessControl.DEVICE;
    return await apiProvider.fetch.remove(path, id);
}

async function addDevice(device) {
    return await apiProvider.fetch.post(ApiUrlConstants.AccessControl.DEVICE, device);
}

async function updateDevice(device) {
    return await apiProvider.fetch.put(ApiUrlConstants.AccessControl.DEVICE, device);
}

async function getDevice(id) {
    return await apiProvider.fetch.getSingle(ApiUrlConstants.AccessControl.DEVICE, id, false);
}

async function updateDevicePayload(updateInfo) {
    return await apiProvider.fetch.put(ApiUrlConstants.AccessControl.UPDATEDEVICEPAYLOAD, updateInfo);
}

async function triggerMqttDevice(updateInfo) {
    return await apiProvider.fetch.put(ApiUrlConstants.AccessControl.TRIGGERMQTTDEVICE, updateInfo);
}